<script setup>
import { useStore } from '@/store/index.js'
import { storeToRefs } from 'pinia'
import Card from '@/components/Card.vue'
import Carousel from '@/components/Carousel.vue'
import TagArea from '@/components/TagArea.vue'
import MainButton from '@/components/MainButton.vue'
import { META_DESCRIPTION } from '@/utils/constants.js'

const store = useStore()
const { tags, limit } = storeToRefs(store)
const { highlightedArticles, restOfArticles, totRestOfArticles } = useArticlesByTag()
useHead({
  title: 'Blog | MessageNet',
  meta: [
    { name: 'description', content: META_DESCRIPTION }
  ]
})
</script>

<template>
  <section class="main-container px-0">
    <div class="w-full mb-4 lg:mb-12">
      <template v-if="highlightedArticles.length === 1">
        <Card :article="highlightedArticles[0]" />
      </template>
      <Carousel v-else-if="highlightedArticles.length > 1" :articles="highlightedArticles" />
    </div>
  </section>
  <section class="main-container">
    <div class="lg:grid lg:grid-cols-4 lg:gap-12">
      <div class="my-8 lg:my-0 lg:order-last">
        <TagArea :tags="tags" v-if="tags.length > 0"/>
      </div>
      <div class="flex flex-col sm:grid sm:grid-cols-2 sm:auto-rows-fr space-y-8 sm:space-y-0 sm:gap-8 w-full col-span-3">
        <Card v-for="article in restOfArticles" :key="article.id" :article="article"
        class="sm:transition sm:duration-500 sm:ease-in-out sm:transform sm:hover:-translate-y-1 sm:hover:scale-105"
        />
      </div>
    </div>
  </section>
  <section class="main-container py-4 mt-8" v-if="limit < totRestOfArticles">
    <div class="text-center">
      <MainButton variant="transparent-mid"
        @click="store.incrementLimit"
      >
        Carica altri articoli
      </MainButton>
    </div>
  </section>
</template>